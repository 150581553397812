/** @jsx jsx */
import { jsx } from '@emotion/core';
import { Fragment, useEffect, useState } from 'react';
import { Global } from '@emotion/core';

import SEO from "../components/seo";
import globalStyle from '../styles/globalStyle';
import BodyBgWrapper from '../components/BodyBgWrapper';
import anydayLogo from '../images/anyday-logo.svg';
import { spacing, breakpoints } from '../utils/preset';

const AGNES_ANYDAY_URL = 'https://anyday.com.au/agnes-venue-page';

const Redirect = () => {
    const [countdown, setCountdown] = useState(3);

    useEffect(() => {
        const timeout = setTimeout(() => {
            window.location.replace(AGNES_ANYDAY_URL);
        }, 3000);

        return () => {
            clearTimeout(timeout);
        };
    }, []);

    useEffect(() => {
        const interval = setInterval(() => {
            setCountdown(prev => prev > 0 ? prev - 1 : 0);
        }, 1000);

        return () => {
            clearInterval(interval);
        };
    }, []);

    return (
        <Fragment>
            <SEO title="Home" />
            <Global styles={globalStyle} />

            <BodyBgWrapper>
                <div
                    css={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        paddingTop: '33vh',
                        paddingBottom: spacing.lg,
                        paddingLeft: spacing.md,
                        paddingRight: spacing.md,
                        textAlign: 'center'
                    }}
                >
                    <a
                        href={AGNES_ANYDAY_URL}
                        aria-label="Go to Anyday website"
                        css={{
                            display: 'inline-flex',
                        }}
                    >
                        <img
                            src={anydayLogo}
                            alt="Anyday Logo"
                            aria-hidden="true"
                            css={{ width: 400 }}
                        />
                    </a>

                    <div
                        css={{
                            fontSize: 18,
                            lineHeight: 1.5,
                            [breakpoints.sm]: {
                                fontSize: 20,
                            },
                        }}
                    >
                        Agnes has moved to our new website.<br />In {countdown} seconds, you will be automatically redirected to <a href={AGNES_ANYDAY_URL} aria-label="Go to Anyday website">anyday.com.au</a>
                    </div>
                </div>
            </BodyBgWrapper>
        </Fragment>
    );
};

export default Redirect;
